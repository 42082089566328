<template>
  <SubLayout parentName="SystemManagement" :navData="navData"></SubLayout>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import SubLayout from '@/layout/SubLayout';
import { hasPerms } from "@/utils/auth";
import * as SystemManagement from '@/router/modules/SystemManagement';

export default {
  // `name`与路由保持一致
  name: 'SystemManagement',
  components: {
    SubLayout,
  },
  data() {
    return {};
  },
  computed: {
    // ...mapState(['cachePages']),
    navData() {
      return Object.values(SystemManagement).filter(val => !val.meta.hideMenu && hasPerms(val.meta.permId));
    }
  },
  methods: {
    ...mapMutations(['setCachePages']),
  },
  beforeDestroy() {
    // 从store `cachePages` 清除所有子页面
    // let { cachePages, navData } = this;
    // cachePages = cachePages
    //   .filter(menu => !navData.some(nav => nav.name === menu.name));
    // this.setCachePages(cachePages);
  },
}
</script>